import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ScenarioInterface } from '../../../store/scheduler/scheduler.model';
import { GenericHelperService } from '../../../shared/service/generic.helper.service';
import { SchedulerService } from 'src/app/shared/service/scheduler/scheduler.service';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class SchedulerResolver implements Resolve<BaseOneResponseInterface<ScenarioInterface> | boolean> {
  private readonly schedulerScenariosUrl: string = '/scheduler/scenarios';

  constructor(private router: Router, private schedulerService: SchedulerService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<BaseOneResponseInterface<ScenarioInterface> | boolean> {
    const scenarioId: number = GenericHelperService.tryParseJson<number | null>(route.paramMap.get('id'), null);

    if (!scenarioId || typeof scenarioId !== 'number' || Number.isNaN(scenarioId) || !Number.isInteger(scenarioId)) {
      this.router.navigate([this.schedulerScenariosUrl]);
      return of(false);
    }

    return this.schedulerService.loadScenario(scenarioId).pipe(
      map((response: BaseOneResponseInterface<ScenarioInterface>) => {
        if (!response.success || !Object.keys(response.data).length) {
          this.router.navigate([this.schedulerScenariosUrl]);
          return false;
        }

        return response;
      }),
      catchError(() => {
        this.router.navigate([this.schedulerScenariosUrl]);
        return of(false);
      }),
    );
  }
}
