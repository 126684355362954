import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import * as _ from 'lodash';
import { ScwMatCheckboxGroupComponent } from '../scw-mat-checkbox-group/scw-mat-checkbox-group.component';
import { ScwMatInputComponent } from '../scw-mat-input/scw-mat-input.component';
import { ScwMatSelectComponent } from '../scw-mat-select/scw-mat-select.component';
import { ScwMatButtonComponent } from '../scw-mat-button/scw-mat-button.component';
import { ScwMatDatepickerComponent } from '../scw-mat-datepicker/scw-mat-datepicker.component';
import { ScwMatPickerComponent } from '../scw-mat-picker/scw-mat-picker.component';
import { ScwMatTextareaComponent } from '../scw-mat-textarea/scw-mat-textarea.component';
import { ScwMatRadioGroupComponent } from '../scw-mat-radio-group/scw-mat-radio-group.component';
import { ScwMatCronInputComponent } from '../scw-mat-cron-input/scw-mat-cron-input/scw-mat-cron-input.component';
import { Subject } from 'rxjs';
import { ScwMatTimepickerComponent } from '../scw-mat-timepicker/scw-mat-timepicker.component';
import { ScwMatWeekdayPickerComponent } from '../scw-mat-weekday-picker/scw-mat-weekday-picker.component';
import { DynamicComponentDirective } from '../../directive/dynamic-component.directive';
import { ScwMatCheckboxComponent } from '../scw-mat-checkbox/scw-mat-checkbox.component';

@Component({
  selector: 'scw-mat-form',
  templateUrl: './scw-mat-form.component.html',
  styleUrls: ['./scw-mat-form.component.scss'],
})
export class ScwMatFormComponent implements OnInit, AfterContentInit, AfterContentChecked, OnDestroy {
  @Input() overrideButtons: QueryList<ScwMatButtonComponent>;
  @Input() overrideCheckboxGroups: QueryList<ScwMatCheckboxGroupComponent>;
  @Input() overrideSelects: QueryList<ScwMatSelectComponent>;
  @Input() overrideInputs: QueryList<ScwMatInputComponent>;
  @Input() overrideDatePickers: QueryList<ScwMatDatepickerComponent>;
  @Input() overridePickers: QueryList<ScwMatPickerComponent>;
  @Input() overrideTextAreas: QueryList<ScwMatTextareaComponent>;
  @Input() overrideRadioGroups: QueryList<ScwMatRadioGroupComponent>;
  @Input() overrideCronInputs: QueryList<ScwMatCronInputComponent>;
  @Input() overrideTimePickers: QueryList<ScwMatTimepickerComponent>;
  @Input() overrideWeekdayPickers: QueryList<ScwMatWeekdayPickerComponent>;
  @Input() overrideCheckboxes: QueryList<ScwMatWeekdayPickerComponent>;
  @Input() validateOnOpen: boolean = false;
  @Input() triggerRuleCheckersSubject: Subject<void>;

  @ContentChildren(ScwMatCheckboxGroupComponent, { descendants: true })
  _checkboxGroups: QueryList<ScwMatCheckboxGroupComponent>;
  @ContentChildren(ScwMatInputComponent, { descendants: true }) public _inputs: QueryList<ScwMatInputComponent>;
  @ContentChildren(ScwMatSelectComponent, { descendants: true }) public _selects: QueryList<ScwMatSelectComponent>;
  @ContentChildren(ScwMatDatepickerComponent, { descendants: true })
  public _datePickers: QueryList<ScwMatDatepickerComponent>;
  @ContentChildren(ScwMatButtonComponent, { descendants: true }) public _buttons: QueryList<ScwMatButtonComponent>;
  @ContentChildren(ScwMatPickerComponent, { descendants: true }) public _pickers: QueryList<ScwMatPickerComponent>;
  @ContentChildren(ScwMatTextareaComponent, { descendants: true })
  public _textAreas: QueryList<ScwMatTextareaComponent>;
  @ContentChildren(ScwMatRadioGroupComponent, { descendants: true })
  public _radioGroups: QueryList<ScwMatRadioGroupComponent>;
  @ContentChildren(ScwMatTimepickerComponent, { descendants: true })
  public _timePickers: QueryList<ScwMatTimepickerComponent>;
  @ContentChildren(ScwMatWeekdayPickerComponent, { descendants: true })
  public _weekdayPickers: QueryList<ScwMatWeekdayPickerComponent>;
  @ContentChildren(ScwMatCheckboxComponent, { descendants: true })
  public _checkboxes: QueryList<ScwMatCheckboxComponent>;
  @ContentChildren(
    DynamicComponentDirective<
      | ScwMatCheckboxGroupComponent
      | ScwMatInputComponent
      | ScwMatSelectComponent
      | ScwMatDatepickerComponent
      | ScwMatButtonComponent
      | ScwMatPickerComponent
      | ScwMatTextareaComponent
      | ScwMatRadioGroupComponent
      | ScwMatTimepickerComponent
      | ScwMatWeekdayPickerComponent
      | ScwMatCheckboxComponent
    >,
    { descendants: true },
  )
  public dynamicComponents: QueryList<
    DynamicComponentDirective<
      | ScwMatCheckboxGroupComponent
      | ScwMatInputComponent
      | ScwMatSelectComponent
      | ScwMatDatepickerComponent
      | ScwMatButtonComponent
      | ScwMatPickerComponent
      | ScwMatTextareaComponent
      | ScwMatRadioGroupComponent
      | ScwMatTimepickerComponent
      | ScwMatWeekdayPickerComponent
      | ScwMatCheckboxComponent
    >
  >;

  @Output() scwMatFormSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ContentChildren(ScwMatCronInputComponent, { descendants: true })
  public cronInputList: QueryList<ScwMatCronInputComponent>;

  public checkboxGroups: QueryList<ScwMatCheckboxGroupComponent>;
  public isValid: boolean;
  public buttons: QueryList<ScwMatButtonComponent>;
  public inputs: QueryList<ScwMatInputComponent>;
  public selects: QueryList<ScwMatSelectComponent>;
  public datePickers: QueryList<ScwMatDatepickerComponent>;
  public pickers: QueryList<ScwMatPickerComponent>;
  public textAreas: QueryList<ScwMatTextareaComponent>;
  public radioGroups: QueryList<ScwMatRadioGroupComponent>;
  public cronInputs: QueryList<ScwMatCronInputComponent>;
  public timePickers: QueryList<ScwMatTimepickerComponent>;
  public weekdayPickers: QueryList<ScwMatWeekdayPickerComponent>;
  public checkboxes: QueryList<ScwMatCheckboxComponent>;

  public ngOnInit(): void {
    this.triggerRuleCheckersSubject?.subscribe(() => this.triggerRuleCheckers());
  }

  public getFormElements(): (
    | ScwMatCheckboxGroupComponent[]
    | ScwMatInputComponent[]
    | ScwMatSelectComponent[]
    | ScwMatDatepickerComponent[]
    | ScwMatButtonComponent[]
    | ScwMatPickerComponent[]
    | ScwMatTextareaComponent[]
    | ScwMatRadioGroupComponent[]
    | ScwMatTimepickerComponent[]
    | ScwMatWeekdayPickerComponent[]
    | ScwMatCheckboxComponent[]
  )[] {
    return [
      _.get(this.checkboxGroups, '_results'),
      _.get(this.inputs, '_results'),
      _.get(this.selects, '_results'),
      _.get(this.datePickers, '_results'),
      _.get(this.pickers, '_results'),
      _.get(this.textAreas, '_results'),
      _.get(this.radioGroups, '_results'),
      _.get(this.cronInputs, '_results'),
      _.get(this.timePickers, '_results'),
      _.get(this.weekdayPickers, '_results'),
      _.get(this.checkboxes, '_results'),
      this.dynamicComponents
        .filter((dynamicComponent) =>
          [
            ScwMatCheckboxGroupComponent,
            ScwMatInputComponent,
            ScwMatSelectComponent,
            ScwMatDatepickerComponent,
            ScwMatButtonComponent,
            ScwMatPickerComponent,
            ScwMatTextareaComponent,
            ScwMatRadioGroupComponent,
            ScwMatTimepickerComponent,
            ScwMatWeekdayPickerComponent,
            ScwMatCheckboxComponent,
          ].some(
            (inputClass) =>
              dynamicComponent?.componentInstance?.value &&
              dynamicComponent.componentInstance.value instanceof inputClass,
          ),
        )
        .map((c) => c.componentInstance.value),
    ];
  }

  private executeBulkCommonFormElementMethod(method: 'checkRules' | 'reset'): void {
    const formElements = this.getFormElements();

    for (const formElement of formElements) {
      for (const element of formElement) {
        element[method]();
      }
    }
  }

  private isValidChecker(): void {
    const formElements = this.getFormElements();

    for (const formElement of formElements) {
      if (_.find(formElement, { isValid: false }) === undefined) {
        this.isValid = true;
      } else {
        this.isValid = false;
        return;
      }
    }
  }

  public reset(): void {
    this.executeBulkCommonFormElementMethod('reset');
  }

  public triggerRuleCheckers(): boolean {
    this.executeBulkCommonFormElementMethod('checkRules');
    this.isValidChecker();

    return this.isValid;
  }

  public ngAfterContentChecked(): void {
    this.isValidChecker();
  }

  public ngAfterContentInit(): void {
    this.buttons = this.overrideButtons ?? this._buttons;
    this.checkboxGroups = this.overrideCheckboxGroups ?? this._checkboxGroups;
    this.inputs = this.overrideInputs ?? this._inputs;
    this.selects = this.overrideSelects ?? this._selects;
    this.datePickers = this.overrideDatePickers ?? this._datePickers;
    this.pickers = this.overridePickers ?? this._pickers;
    this.textAreas = this.overrideTextAreas ?? this._textAreas;
    this.radioGroups = this.overrideRadioGroups ?? this._radioGroups;
    this.cronInputs = this.overrideCronInputs ?? this.cronInputList;
    this.timePickers = this.timePickers ?? this._timePickers;
    this.weekdayPickers = this.weekdayPickers ?? this._weekdayPickers;
    this.checkboxes = this.checkboxes ?? this._checkboxes;

    this.buttons.forEach((item) => {
      if (item.isSubmitButton !== false) {
        item.onClickEmitterForScwMatForm = () => {
          this.submitData();
        };
      }
    });

    if (this.validateOnOpen) {
      this.triggerRuleCheckers();
    }
  }

  public partialTriggerRuleCheckers(): void {
    const formElements: any[] = this.getFormElements();
    for (const formElement of formElements) {
      for (const element of formElement) {
        if (!element['hasValidationControl']) {
          element['checkRules']();
        }
      }
    }
  }

  public ngOnDestroy(): void {
    this.triggerRuleCheckersSubject?.unsubscribe();
  }

  public submitData() {
    this.triggerRuleCheckers();
    this.scwMatFormSubmit.emit(this.isValid);
  }
}
