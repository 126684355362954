import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Cell, Column, DataValidation, Row, ValueType, Workbook, Worksheet, Style } from 'exceljs';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { OeeAppState } from '../../../store/oee.reducer';
import { IRecordRangeOptions } from '../../../store/settings/product-speeds/product-speeds.model';
import { ExcelDropdownInterface } from '../../../store/settings/users/users.model';
import { customDotDateFormat, mysqlDateFormat, mysqlTimestampFormat } from '../../helper/date';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import {
  excelDateFormat,
  excelDateFormats,
  excelTimeFormat,
  excelTimeFormats,
} from '../../model/enum/excel-date-format';
import { BulkResponseDataInterface } from '../../model/interface/crud-response-interface.model';
import { GenericHelperService } from '../generic.helper.service';
import { ECellTypes, EDependentBehavior, EExcelColumnWidth, EExcelSheetState, EExcelSheetType } from './excel.enum';
import {
  CELL_ADDRESS_CONSTANT,
  EExcelDateFormatOption,
  EXCEL_DEFAULT_DATE_FORMAT,
  EXCEL_DEFAULT_TIME_FORMAT,
  EXCEL_FONT_FAMILY,
  EXCEL_UPLOAD_EVENT_TELEMETRY,
  ICreateExcel,
  ICreateExcelSheet,
  IDataWithExcelId,
  IDependentColumnConfiguration,
  IEnumeratedDropdownOption,
  IExcelCellConfiguration,
  IExcelColumnDefinition,
  IExcelColumnInfoConfiguration,
  IExcelColumnKeys,
  IExcelDateFormatInformation,
  IExcelReadmeSheet,
  IOEEExportLog,
  IOEEExcelUploadRow,
} from './excel.helper';
import { HelperService } from '../helper.service';
import * as AppActions from '../../../store/app/actions';
import { ITableHeader } from '../../../../constants.model';
import { MonitoringService } from '../error-service/monitoring.service';

@Injectable({ providedIn: 'root' })
export class ExcelHelperService {
  constructor(
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private readonly monitoringService: MonitoringService,
  ) {}

  public downloadSimpleExcel(
    excelColumns: Required<ICreateExcel>,
    titleTranslateKey: string,
    logInformation: IOEEExportLog,
  ): void {
    const sheetTitle: string = this.translate.instant(titleTranslateKey);
    const dateFormatInformation: IExcelDateFormatInformation = this.getUserDateFormatInformation();
    const excelName: string = `${sheetTitle} ${moment()
      .tz(dateFormatInformation.timezone)
      .format(dateFormatInformation.dateFormat$)}`;

    const worksheets: ICreateExcelSheet[] = [
      {
        sheetTitle,
        sheetType: EExcelSheetType.TABLE,
        params: excelColumns,
        withData: true,
        isDisabledColumnsFirstLine: true,
        excelRowFormatLimit: excelColumns.data.length + 1,
      },
    ];

    this.createExcel(
      excelName,
      logInformation,
      worksheets,
      dateFormatInformation.timezone,
      dateFormatInformation.dateFormat$,
      dateFormatInformation.timeFormat$,
      false,
    ).then(
      () => {
        this.store.dispatch(new AppActions.HideLoader());
      },
      () => {
        this.store.dispatch(new AppActions.HideLoader());
      },
    );
  }

  public static getGenericReadonlyExcelColumn(
    datatableHeaders: ITableHeader[],
    headerValue: string,
    width: number = 20,
  ): IExcelColumnDefinition {
    return {
      width,
      key: headerValue,
      header: _.find(datatableHeaders, { value: headerValue }).name,
      type: ValueType.String,
      style: { numFmt: '@' },
      dataValidation: {
        type: ECellTypes.CUSTOM,
        formulae: [],
        showErrorMessage: false,
        showInputMessage: false,
      },
    };
  }

  public static updateExcelDropdownOptionsWithData<TypeData, TypeOption>(
    excelData: any[],
    dropdownOptions: TypeOption[],
    key: string,
  ): TypeOption[] {
    const optionsFromData: ArrayLike<unknown> = excelData.reduce((filteredData: Set<TypeOption>, product) => {
      const value = _.get(product, key, null);

      if (value !== null) {
        filteredData.add(value);
      }

      return filteredData;
    }, new Set());

    return _.unionBy(dropdownOptions, Array.from(optionsFromData), 'id');
  }

  public static generateDependentOptionProperties(
    dependentId: string | number,
    dependentLabel: string,
    relatedId: string | number,
    relatedLabel: string,
  ): IDataWithExcelId {
    return {
      excelId: `${relatedId}-${dependentId}`,
      excelLabel: `${dependentLabel} (${relatedLabel})`,
    };
  }

  public static generateDependentOptions<DependentType = object, RelatedType = object>(
    dependent: IDependentColumnConfiguration<DependentType>,
    related: IDependentColumnConfiguration<RelatedType>,
    behaviour: EDependentBehavior = EDependentBehavior.MULTIPLY,
  ): (DependentType & IDataWithExcelId)[] {
    if (behaviour === 'map') {
      return dependent.data.map((dependentData: DependentType) => {
        const relatedData: RelatedType | undefined = related.data.find((relatedData: RelatedType) => {
          let dependentId: string;
          let relatedId: string;

          if (dependentData[dependent.key]) {
            dependentId = String(dependentData[dependent.key]);
          }

          if (relatedData[related.key]) {
            relatedId = String(relatedData[related.key]);
          }

          return dependentId && relatedId && dependentId === relatedId;
        });

        return {
          ...dependentData,
          ...ExcelHelperService.generateDependentOptionProperties(
            String(dependentData[dependent.key]),
            String(dependentData[dependent.label]),
            String(relatedData[related.key]),
            String(relatedData[related.label]),
          ),
        };
      });
    }

    return related.data.reduce((result: (DependentType & IDataWithExcelId)[], relatedOption: RelatedType) => {
      let dependentItems: DependentType[] = dependent.data;
      const relatedField: RelatedType[Extract<keyof RelatedType, string>] = relatedOption[related.relatesToField];

      if (typeof relatedField === 'string') {
        dependentItems = dependent.data.filter((data: DependentType) =>
          relatedField.split(',').includes(String(data[dependent.key])),
        );
      }

      return result.concat(
        dependentItems.map((dependentOption: DependentType) => {
          return {
            ...dependentOption,
            ...ExcelHelperService.generateDependentOptionProperties(
              String(dependentOption[dependent.key]),
              String(dependentOption[dependent.label]),
              String(relatedOption[related.key]),
              String(relatedOption[related.label]),
            ),
          };
        }),
      );
    }, []);
  }

  public createExcel(
    fileName: string,
    logInformation: IOEEExportLog,
    worksheets: ICreateExcelSheet[] = [],
    timezone: string = 'utc',
    dateFormat: string = EXCEL_DEFAULT_DATE_FORMAT,
    timeFormat: string = EXCEL_DEFAULT_TIME_FORMAT,
    createAsCsvFile: boolean = false,
    indexOfWorksheetToPrintToCsv: number = 1,
    columnSpecificDateFormats: Record<string, EExcelDateFormatOption> = {},
  ) {
    this.monitoringService.logEvent(
      { name: 'OEE Export' },
      { asExcel: true, ...(createAsCsvFile && { asCsv: true }), ...logInformation },
    );

    const workbook: Workbook = new Workbook();
    workbook.creator = 'Supply Chain Wizard';
    workbook.created = moment().tz(timezone).toDate();
    workbook.calcProperties.fullCalcOnLoad = true;

    worksheets.forEach((worksheet: ICreateExcelSheet) => {
      this.createExcelWorksheet(workbook, worksheet, dateFormat, timeFormat, timezone, columnSpecificDateFormats);
    });

    return ExcelHelperService.createRequestedExcelFileType(
      workbook,
      fileName,
      createAsCsvFile,
      indexOfWorksheetToPrintToCsv,
    );
  }

  public excelRowToDto<T>(excelRow: any, columnKeys: IExcelColumnKeys, changeOptions: { [key: string]: any }): T {
    let dto = {} as T;

    for (const [key, value] of Object.entries(excelRow)) {
      if (columnKeys[key]) {
        const isListItem: boolean = columnKeys[key].dataValidationType === ECellTypes.LIST;
        let cellValue: any = value === 0 ? 0 : value || null;
        const valueResult = _.has(value, 'result');

        if (valueResult) {
          // @ts-ignore
          cellValue = value.result === 0 ? 0 : value.result || null;
        }

        const dateColumnFormat: string = columnKeys[key].isDateTimeFormat
          ? `${changeOptions['dateFormat']} ${changeOptions['timeFormat']}`
          : changeOptions['dateFormat'];

        if (cellValue instanceof Date) {
          cellValue = this.translate.instant('general.invalidDate');
        }

        if (
          columnKeys[key].type === ValueType.Date &&
          moment(cellValue, dateColumnFormat, true).isValid() &&
          !isListItem
        ) {
          cellValue = moment(cellValue, dateColumnFormat).startOf('minute').format(mysqlTimestampFormat);
        }

        if (isListItem) {
          cellValue = _.get(
            excelRow,
            `${key} ${this.translate.instant('apiErrorMessages.properties.id')}.result`,
            cellValue,
          );
        }

        if (columnKeys[key].type === ValueType.String && cellValue !== null && !isListItem) {
          cellValue = String(cellValue).trim() ? String(cellValue) : null;
        }

        if (
          columnKeys[key].type === ValueType.Number &&
          cellValue !== null &&
          !isNaN(cellValue) &&
          !isListItem &&
          !columnKeys[key].isDecimalNumber
        ) {
          cellValue = Number(cellValue);
        }

        if (columnKeys[key].type === ValueType.Boolean && cellValue !== null) {
          cellValue = GenericHelperService.transformToBoolean(cellValue);
        }

        if (columnKeys[key].removePropertyIfNull && _.isNil(cellValue)) {
          continue;
        }

        if (cellValue !== null && columnKeys[key].isDecimalNumber === true) {
          cellValue = this.decimalHelper.sanitizeString(cellValue);
        }

        dto = Object.assign(dto, {
          [columnKeys[key].key]: cellValue,
        });
      }
    }

    return dto;
  }

  public static async getExcelWorkBookFromFile(file: File): Promise<Workbook> {
    const fileBuffer: ArrayBuffer = await new Response(file).arrayBuffer();
    const workbook: Workbook = new Workbook();
    await workbook.xlsx.load(fileBuffer);

    return workbook;
  }

  public getExcelRowsFromWorkSheet<T>(
    workSheet: Worksheet,
    columnKeys: IExcelColumnKeys,
    changeOptions: { [key: string]: unknown } = {},
    resolveHyperLink: boolean = false,
  ): T[] {
    const resultArray: T[] = [];
    let filledRowsHeaders: any[] = [];

    workSheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      if (rowNumber === 1) {
        filledRowsHeaders = row.values as [];
        return;
      }

      let processedValues: any[];

      if (resolveHyperLink) {
        processedValues = Array.isArray(row.values)
          ? row.values.map((value) => {
              if (_.isObject(value) && _.has(value, 'text.richText[0].text')) {
                return _.get(value, 'text.richText[0].text');
              } else if (_.isObject(value)) {
                return _.get(value, 'text');
              }

              return value;
            })
          : [];
      } else {
        processedValues = row.values as [];
      }

      const rowObject = _.zipObject(filledRowsHeaders, processedValues);
      const dto = this.excelRowToDto<T>(rowObject, columnKeys, changeOptions);

      if (!_.isEmpty(_.pickBy(dto, (value: unknown) => value))) {
        resultArray.push(dto);
      }
    });

    return resultArray;
  }

  public getExcelDateFormula(dateFormat: string, dateCharacterFormat: string): string {
    const isJapanDateFormat: boolean = dateFormat === excelDateFormat.jall && dateCharacterFormat === 'll';
    const yearValue: string = isJapanDateFormat ? 'LEFT' : this.getExcelDateLocationFormula(dateFormat, 'YYYY', true);
    const monthValue: string = isJapanDateFormat
      ? `MID(${CELL_ADDRESS_CONSTANT},6,2)`
      : this.getExcelDateLocationFormula(dateFormat, 'MM', false, yearValue === 'LEFT');
    const dayValue: string = isJapanDateFormat
      ? `MID(${CELL_ADDRESS_CONSTANT},9,2)`
      : this.getExcelDateLocationFormula(dateFormat, 'DD', false, yearValue === 'LEFT');

    const year: string = `1970<VALUE(${yearValue}(${CELL_ADDRESS_CONSTANT},4)),VALUE(${yearValue}(${CELL_ADDRESS_CONSTANT},4))<2038`;
    const month: string = `0<VALUE(${monthValue}),VALUE(${monthValue})<13`;
    const day: string = `0<VALUE(${dayValue}),VALUE(${dayValue})<32`;

    return `=IFERROR(AND(${year},${month},${day}),FALSE)`;
  }

  public static mergeBulkResponseWithRequestData<T>(
    response: BulkResponseDataInterface,
    requestData: T[],
    returnSuccesses: boolean = false,
  ): (T & { errorMessages?: string })[] {
    return response.data.reduce((filtered: (T & { errorMessages?: string })[], res, index) => {
      const item = HelperService.cloneDeep(requestData[index]);

      if (!res.success) {
        filtered.push(
          Object.assign(item, {
            errorMessages: Array.from(new Set(res.message)).join(', '),
          }),
        );
      }

      if (returnSuccesses && res.success) {
        filtered.push(item);
      }

      return filtered;
    }, []);
  }

  public static getSheetColumnKeys(columns: IExcelColumnDefinition[]): IExcelColumnKeys {
    let columnKeys: IExcelColumnKeys = {};

    for (const column of columns) {
      columnKeys = Object.assign(columnKeys, {
        [column.header]: {
          key: column.key,
          type: column.type,
          dataValidationType: column.dataValidation?.type,
          isDateTimeFormat: column.specificDateFormat,
          removePropertyIfNull: column.removePropertyIfNull,
          isDecimalNumber: column.isDecimalNumber,
        },
      });
    }

    return columnKeys;
  }

  public prepareExcelColumns(columns: IExcelColumnDefinition[], withErrorColumn: boolean = false): void {
    const defaultColumnDataValidationOptions: Partial<DataValidation> = {
      allowBlank: false,
      formulae: [],
      showErrorMessage: true,
      errorStyle: 'Error',
      showInputMessage: true,
      errorTitle: this.translate.instant('excel.column.errorTitle'),
    };

    for (const column of columns) {
      const promptType = column.dropdownOptions ? 'DropDown' : 'Input';

      Object.assign(column.dataValidation, {
        ...defaultColumnDataValidationOptions,
        ...column.dataValidation,
        error: column.dataValidation.error
          ? column.dataValidation.error
          : this.translate.instant('excel.column.error', { field: column.header }),
        prompt: column.dataValidation.prompt
          ? column.dataValidation.prompt
          : this.translate.instant(`excel.column.prompt${promptType}`, { field: column.header }),
        promptTitle: column.dataValidation.promptTitle
          ? column.dataValidation.promptTitle
          : this.translate.instant(`excel.column.prompt${promptType}Title`, { field: column.header }),
      });
    }

    if (withErrorColumn) {
      columns.push({
        header: this.translate.instant('excel.column.errorTitle'),
        key: 'errorMessages',
        width: EExcelColumnWidth.ERROR,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.CUSTOM,
          allowBlank: false,
          formulae: [],
          showErrorMessage: false,
          error: this.translate.instant('general.error'),
          errorStyle: 'Error',
          showInputMessage: false,
        },
      });
    }
  }

  public static changeOrderOfTheListColumns(columns: Partial<Column>[], worksheetColumns: Partial<Column>[]): void {
    let indexOfTheListColumn = 0;
    for (let i = 0; i < worksheetColumns.length; i = i + 1) {
      if (worksheetColumns[i].key.indexOf('_ID') > 0) {
        worksheetColumns.splice(indexOfTheListColumn, 0, worksheetColumns.splice(i, 1)[0]);
        columns.splice(indexOfTheListColumn, 0, columns.splice(i, 1)[0]);
        // @ts-ignore
        worksheetColumns[i].number = i;
        i = i + 1;
        indexOfTheListColumn = indexOfTheListColumn + 1;
      }
    }
  }

  public getExcelBooleanDropdownOptions(): ExcelDropdownInterface[] {
    return [
      { id: String(true), name: this.translate.instant('general.yes') },
      { id: String(false), name: this.translate.instant('general.no') },
    ];
  }

  public getMultipleCheckInsOptions(): IEnumeratedDropdownOption[] {
    return [
      { id: '2', name: this.translate.instant('general.siteDefault') },
      { id: '1', name: this.translate.instant('general.yes') },
      { id: '0', name: this.translate.instant('general.no') },
    ];
  }

  public getUserDateFormatInformation(): IExcelDateFormatInformation {
    const destroySubject: Subject<boolean> = new Subject<boolean>();
    let timezone: string = 'utc';
    let dateFormat$: string;
    let timeFormat$: string;

    this.store
      .select('user')
      .pipe(takeUntil(destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          timezone = state.timezone;
          if (state.locale !== '') {
            dateFormat$ = excelDateFormat[state.locale];
            timeFormat$ = excelTimeFormat[state.locale];
          }
          destroySubject.next(true);
          destroySubject.complete();
        }
      });

    return { timezone, dateFormat$, timeFormat$ };
  }

  public static getRecordRangeOptions(
    totalData: number,
    excelUploadLimit: number = 1000,
    selectedCount: number = null,
    returnAsOffset = false,
  ): IRecordRangeOptions[] {
    const totalExcelData: number = selectedCount ? selectedCount : totalData;

    return Array.from(Array(Math.ceil(totalData / excelUploadLimit))).map((value, index) => {
      const rangeStart = index * excelUploadLimit;
      const rangeEnd = rangeStart + excelUploadLimit;

      return {
        value: returnAsOffset ? String(rangeStart) : String(index + 1),
        label: `${rangeStart + 1} - ${rangeEnd < totalExcelData ? rangeEnd : totalExcelData}`,
      };
    });
  }

  public getExcelColumnInfo(
    columnKey: string,
    excelColumnInfoConfiguration: IExcelColumnInfoConfiguration,
  ): Pick<IExcelColumnDefinition, 'type' | 'style' | 'specificDateFormat'> &
    Partial<Pick<IExcelColumnDefinition, 'width'>> {
    let type: ValueType;
    let numFmt: string;
    let width: number;

    if (
      [
        ...(excelColumnInfoConfiguration.dateTimeFields ?? []),
        ...(excelColumnInfoConfiguration.dateFields ?? []),
      ].includes(columnKey)
    ) {
      type = ValueType.Date;
      numFmt = '@';
    } else if ((excelColumnInfoConfiguration.integerFields ?? []).includes(columnKey)) {
      type = ValueType.Number;
      numFmt = '0';
    } else if ((excelColumnInfoConfiguration.decimalFields ?? []).includes(columnKey)) {
      type = ValueType.Number;
      numFmt = '0.000000000000000###############';
      width = EExcelColumnWidth.DECIMAL;
    } else {
      type = ValueType.String;
      numFmt = '@';
    }

    return {
      type,
      ...(width ? { width } : {}),
      specificDateFormat: excelColumnInfoConfiguration.dateTimeFields?.includes(columnKey)
        ? EExcelDateFormatOption.Datetime
        : EExcelDateFormatOption.Date,
      style: { numFmt },
    };
  }

  public logExcelUploadOperation(
    name: string,
    allRows: IOEEExcelUploadRow[],
    failedRows: IOEEExcelUploadRow[],
    idNeverExists: boolean = false,
  ): void {
    let failedCreateCount: number = 0;
    let failedUpdateCount: number = 0;
    let totalCreateCount: number = 0;
    let totalUpdateCount: number = 0;
    let successfulCreateCount: number = 0;
    let successfulUpdateCount: number = 0;

    for (const row of failedRows) {
      if (row.id) {
        failedUpdateCount++;
      } else {
        failedCreateCount++;
      }
    }

    for (const row of allRows) {
      if (row.id) {
        totalUpdateCount++;
      } else {
        totalCreateCount++;
      }
    }

    successfulCreateCount = totalCreateCount - failedCreateCount;
    successfulUpdateCount = totalUpdateCount - failedUpdateCount;

    const siteIds: number[] = allRows.reduce((acc: number[], row: IOEEExcelUploadRow): number[] => {
      if (row.siteId && !acc.includes(row.siteId)) {
        acc.push(row.siteId);
      }

      return acc;
    }, []);

    this.monitoringService.logEvent(EXCEL_UPLOAD_EVENT_TELEMETRY, {
      name: _.camelCase(name.toLowerCase()),
      ...(idNeverExists
        ? { successfulCount: successfulCreateCount, failedCount: failedCreateCount }
        : {
            successfulCreateCount,
            successfulUpdateCount,
            failedCreateCount,
            failedUpdateCount,
          }),
      siteIds: siteIds?.length ? siteIds.join(',') : '',
    });
  }

  private static prepareDefaultColumn(column: Partial<IExcelColumnDefinition>): Partial<IExcelColumnDefinition> {
    return {
      collapsed: _.get(column, 'collapsed', false),
      defn: _.get(column, 'defn', undefined),
      headerCount: _.get(column, 'headerCount', 0),
      headers: _.get(column, 'headers', []),
      isCustomWidth: _.get(column, 'isCustomWidth', false),
      isDefault: _.get(column, 'isDefault', false),
      letter: _.get(column, 'letter', ''),
      number: _.get(column, 'number', 0),
      header: _.get(column, 'header', ''),
      key: _.get(column, 'key', ''),
      width: _.get(column, 'width', 30),
      outlineLevel: _.get(column, 'outlineLevel', 0),
      hidden: _.get(column, 'hidden', false),
      style: _.get(column, 'style', { font: { bold: false, size: 11 }, protection: { locked: false } }),
      border: _.get(column, 'border', null),
      fill: _.get(column, 'fill', null),
      numFmt: _.get(column, 'numFmt', null),
      font: _.get(column, 'font', null),
      alignment: _.get(column, 'alignment', null),
      protection: _.get(column, 'protection', { locked: false }),
    };
  }

  private static prepareHiddenSheet(column: IExcelColumnDefinition, workbook: Workbook): void {
    if (column.dropdownOptions !== undefined && _.isArray(column.dropdownOptions.data)) {
      const newSheetName: string = `${column.key}DataSheet`;
      _.set(column.dropdownOptions, 'sheetName', newSheetName);
      const newSheet: Worksheet = workbook.addWorksheet(newSheetName, {
        headerFooter: { firstHeader: `${column.key}DataSheet`, firstFooter: `${column.key}DataSheet` },
      });
      const newSheetColumns: Partial<IExcelColumnDefinition>[] = [];

      let property: string;
      let id: string = 'id';

      switch (column.dropdownOptions.dataProperty) {
        case 'siteName':
          property = 'name';
          break;
        case 'productId':
          property = 'productId';
          break;
        case 'scheduledLineTitle':
          property = 'title';
          break;
        default:
          property = column.dropdownOptions.dataProperty.split('.')[1];
          id = column.dropdownOptions.dataId.split('.')[1];
      }

      newSheetColumns.push(
        {
          header: property,
          key: property,
          type: ValueType.String,
          style: { numFmt: '@' },
        },
        {
          header: id,
          key: id,
          type: ValueType.String,
          style: { numFmt: '@' },
        },
      );

      newSheet.columns = newSheetColumns;
      newSheet.state = EExcelSheetState.VERY_HIDDEN;

      column.dropdownOptions.data.forEach((item) => {
        const addItem = {};
        _.set(addItem, property, _.get(item, property, null));
        _.set(addItem, id, _.get(item, id, null));
        newSheet.addRow(addItem).commit();
      });

      if (column.dataValidation !== undefined) {
        column.dataValidation.formulae = [];
      }

      column.dataValidation.type = ECellTypes.LIST;

      const dataLength: number = column.dropdownOptions.data.length || 1;

      const dropdownColumn: Partial<Column> = newSheet.getColumn(property);

      column.dataValidation.formulae.push(
        `${newSheetName}!$${dropdownColumn.letter}$2:$${dropdownColumn.letter}$${dataLength + 1}`,
      );
    }
  }

  private static prepareDropdownColumnData(
    column: IExcelColumnDefinition,
    data: any,
    dateFormat: string,
    timeFormat: string,
  ): void {
    const dropdownOptions = _.get(column, 'dropdownOptions', undefined);

    if (dropdownOptions && column.type !== ValueType.Date) {
      data[column.key] = _.get(data, dropdownOptions.dataProperty, null);
    }

    if (column.type === ValueType.Date && data) {
      const value = _.get(data, column.key, null);
      let columnValue: string = null;

      if (value !== null) {
        let momentDate: moment.Moment = moment(value, mysqlTimestampFormat, true);

        if (column.specificDateFormat !== EExcelDateFormatOption.Datetime && !momentDate.isValid()) {
          momentDate = moment(value, mysqlDateFormat, true);
        }

        columnValue = momentDate.isValid()
          ? momentDate.format(
              column.specificDateFormat === EExcelDateFormatOption.Datetime
                ? `${dateFormat} ${timeFormat}`
                : dateFormat,
            )
          : value;
      }

      data[column.key] = columnValue;
    }
  }

  private static addListColumnValidation(column: IExcelColumnDefinition, cell: Cell): Object {
    const cellValue = {};
    try {
      const sheetName = _.get(column.dropdownOptions, 'sheetName', null);
      if (sheetName !== null) {
        const workbook = cell.workbook;
        const additionalSheet = workbook.getWorksheet(sheetName);
        const currentSheet = workbook.getWorksheet(cell.worksheet.name);
        const additionalColumn: Partial<Column> = currentSheet.getColumn(`${column.key}_ID`);
        const idColumn: Partial<Column> = additionalSheet.getColumn(column.dropdownOptions.dataId.split('.')[1]);
        const value = `IFERROR(VLOOKUP(${cell.address}&"", ${sheetName}!${additionalSheet.dimensions.$range}, ${idColumn.number}, FALSE), "")`;
        _.set(cellValue, 'columnLetter', `${additionalColumn.letter}${cell.row}`);
        _.set(cellValue, 'formula', value);
      }
      return cellValue;
    } catch (e) {
      return cellValue;
    }
  }

  private static protectWorksheet(
    worksheet: Worksheet,
    excelRowFormatLatestLimit: number,
    isProtected: boolean = true,
  ): void {
    const latestRow = worksheet.getRow(excelRowFormatLatestLimit);
    if (latestRow !== undefined) {
      latestRow.values = null;
    }

    if (isProtected) {
      worksheet.protect(null, {
        selectLockedCells: false,
        selectUnlockedCells: true,
        insertRows: true,
        // spinCount: excelRowFormatLatestLimit,
      });
    }
  }

  private static fillExcelColumnFormat(columns: IExcelColumnDefinition[], dateFormat): void {
    columns.forEach((column: Partial<IExcelColumnDefinition>) => {
      if (column.type === ValueType.Date && dateFormat) {
        column.numFmt = '@';
        _.set(column, 'style.numFmt', '@');
        column.style.alignment = { vertical: 'middle', horizontal: 'center' };
      }

      if (column.type === ValueType.Number) {
        column.dataValidation.type = ECellTypes.DECIMAL;
      }
    });
  }

  private static setColumnConfigurations(column: IExcelColumnDefinition, number: number): Partial<Column> {
    const worksheetColumn: Partial<Column> = ExcelHelperService.prepareDefaultColumn({ ...column, number });
    _.set(worksheetColumn, 'style.protection.locked', false);
    _.set(worksheetColumn, 'protection.locked', false);

    if (_.get(column, 'isReadOnly', null)) {
      ExcelHelperService.setReadonlyTypeColumnConfiguration(worksheetColumn);
    }

    if (column.key === 'id') {
      ExcelHelperService.setIdColumnConfiguration(worksheetColumn);
    }

    return worksheetColumn;
  }

  private static setReadonlyTypeColumnConfiguration(worksheetColumn: Partial<Column>): void {
    _.set(worksheetColumn, 'style.protection.locked', true);
    _.set(worksheetColumn, 'protection.locked', true);
    _.set(worksheetColumn, 'style.font.name', EXCEL_FONT_FAMILY.arial.name);
    _.set(worksheetColumn, 'style.font.color', { argb: 'C0C0C0' });
    _.set(worksheetColumn, 'style.font.family', EXCEL_FONT_FAMILY.arial.value);
    _.set(worksheetColumn, 'style.font.size', 10);
  }

  private static setIdColumnConfiguration(worksheetColumn: Partial<Column>): void {
    _.set(worksheetColumn, 'style.protection.locked', true);
    _.set(worksheetColumn, 'protection.locked', true);
    _.set(worksheetColumn, 'hidden', true);
  }

  private static createAdditionalCell(
    column: IExcelColumnDefinition,
    additionalColumn: Object,
    data: any[],
    worksheet: Worksheet,
    dataIndex: number,
    withData: boolean,
  ): void {
    const additionalCell: Cell = worksheet.getCell(additionalColumn['columnLetter']);
    additionalCell.value = {
      formula: additionalColumn['formula'],
      result: undefined,
      date1904: undefined,
    };

    if (_.get(column, 'dataValidation.type', null) === ECellTypes.LIST && withData && data.length && data[dataIndex]) {
      _.set(data[dataIndex], `${column.key}_ID`, {
        formula: _.get(additionalColumn, 'formula'),
        value: _.get(data[dataIndex], column.dropdownOptions.dataId, null),
        result: _.get(data[dataIndex], column.dropdownOptions.dataId, null),
        date1904: false,
      });
    }
  }

  private static setupCellValidation(column: IExcelColumnDefinition, cell: Cell): void {
    const formulae: string[] = [];
    if (_.get(column, 'dataValidation.formulae', false)) {
      const re = /\$_CELLADDRESS_\$/gi;
      for (const formula of column.dataValidation.formulae) {
        if (typeof formula === 'string' && formula) {
          formulae.push(`${formula.replace(re, cell.address)}`);
        }
      }
    }

    _.set(cell, 'dataValidation', { ...column.dataValidation, ...{ formulae } });
  }

  private addDateColumnValidation(
    column: IExcelColumnDefinition,
    dateFormat: string,
    timeFormat: string,
    timezone: string,
    addFormula: boolean = true,
    columnSpecificDateFormats: Record<string, EExcelDateFormatOption>,
  ): void {
    if (column.type !== ValueType.Date) {
      return;
    }

    if (columnSpecificDateFormats[column.key] === EExcelDateFormatOption.Date) {
      dateFormat = customDotDateFormat;
    }

    if (column.dataValidation.formulae.length === 0 && addFormula) {
      column.dataValidation.type = 'date';
      column.dataValidation.operator = 'between';
      column.dataValidation.formulae = [
        moment('01-01-1970 00:00:00').tz(timezone).toDate().toString(),
        moment().add(5, 'years').tz(timezone).toDate().toString(),
      ];
    }

    this.addPromptAndErrorMessagesToDateColumn(column, dateFormat, timeFormat);
  }

  private createExcelWorksheet(
    workbook: Workbook,
    worksheetConfiguration: ICreateExcelSheet,
    dateFormat: string,
    timeFormat: string,
    timezone: string,
    columnSpecificDateFormats: Record<string, EExcelDateFormatOption>,
  ): void {
    const { sheetTitle, sheetType, messages } = worksheetConfiguration;

    const worksheet: Worksheet = workbook.addWorksheet(sheetTitle, {
      headerFooter: {
        firstHeader: sheetTitle,
        firstFooter: sheetTitle,
      },
    });

    switch (sheetType) {
      case EExcelSheetType.README:
        ExcelHelperService.createReadmeWorksheet(worksheet, messages || this.createDefaultExcelReadmeConfiguration());
        break;
      case EExcelSheetType.ACTIVITY_HISTORY_README:
        ExcelHelperService.createReadmeWorksheet(
          worksheet,
          messages || this.createActivityHistoryExcelReadmeConfiguration(),
        );
        break;
      default:
        this.createTableWorksheet(
          workbook,
          worksheet,
          worksheetConfiguration,
          dateFormat,
          timeFormat,
          timezone,
          columnSpecificDateFormats,
        );
        break;
    }
  }

  private createDefaultExcelReadmeConfiguration(): IExcelReadmeSheet {
    const cells: IExcelCellConfiguration[] = [];
    const defaultColumnStyle: Partial<IExcelColumnDefinition> = {
      width: 100,
    };
    const defaultCellStyle: Partial<Style> = {
      font: {
        name: 'Ariel',
        color: {
          argb: '000000',
        },
        size: 10,
      },
      alignment: {
        wrapText: true,
      },
    };

    cells.push({
      column: 'A',
      row: 3,
      value: this.translate.instant('activityLogs.excel.readme.title').toUpperCase(),
      style: {
        font: {
          name: 'Roboto',
          bold: true,
          color: { argb: 'FFEA4335' },
        },
      },
    });

    cells.push({
      column: 'A',
      row: 5,
      value: this.translate.instant('activityLogs.excel.readme.description'),
    });

    cells.push({
      column: 'A',
      row: 7,
      value: `- ${this.translate.instant('activityLogs.excel.readme.templateFormatTitle')}`,
    });

    cells.push({
      column: 'A',
      row: 8,
      value: `- ${this.translate.instant('activityLogs.excel.readme.dataAnalysisFormatTitle')}`,
    });

    cells.push({
      column: 'A',
      row: 10,
      value: this.translate.instant('activityLogs.excel.readme.explanationTitle'),
      style: {
        font: {
          bold: true,
        },
      },
    });

    cells.push({
      column: 'A',
      row: 12,
      value: {
        richText: [
          {
            font: { bold: true },
            text: `${this.translate.instant('activityLogs.excel.readme.templateFormatTitle')}: `,
          },
          { text: this.translate.instant('activityLogs.excel.readme.templateFormatDescription') },
        ],
      },
    });

    cells.push({
      column: 'A',
      row: 14,
      value: {
        richText: [
          {
            font: { bold: true },
            text: `${this.translate.instant('activityLogs.excel.readme.dataAnalysisFormatTitle')}: `,
          },
          { text: this.translate.instant('activityLogs.excel.readme.dataAnalysisFormatDescription') },
        ],
      },
    });

    return {
      cells,
      defaultCellStyle,
      defaultColumnStyle,
    };
  }

  private createActivityHistoryExcelReadmeConfiguration(): IExcelReadmeSheet {
    const cells: IExcelCellConfiguration[] = [];
    const defaultColumnStyle: Partial<IExcelColumnDefinition> = {
      width: 100,
    };
    const defaultCellStyle: Partial<Style> = {
      font: {
        name: 'Ariel',
        color: {
          argb: '000000',
        },
        size: 10,
      },
      alignment: {
        wrapText: true,
      },
    };

    cells.push({
      column: 'A',
      row: 3,
      value: this.translate.instant('activityLogs.excel.readme.title').toUpperCase(),
      style: {
        font: {
          name: 'Roboto',
          bold: true,
          color: { argb: 'FFEA4335' },
        },
      },
    });

    cells.push({
      column: 'A',
      row: 5,
      value: this.translate.instant('activityHistory.excel.readme.description'),
    });

    cells.push({
      column: 'A',
      row: 7,
      value: `- ${this.translate.instant('activityHistory.excel.sheet.activityHistoriesData.title')}`,
    });

    cells.push({
      column: 'A',
      row: 8,
      value: `- ${this.translate.instant('activityHistory.excel.sheet.userActivities.title')}`,
    });

    cells.push({
      column: 'A',
      row: 9,
      value: `- ${this.translate.instant('activityHistory.excel.sheet.exampleActivityHistoriesData.title')}`,
    });

    cells.push({
      column: 'A',
      row: 11,
      value: this.translate.instant('activityLogs.excel.readme.explanationTitle'),
      style: {
        font: {
          bold: true,
        },
      },
    });

    cells.push({
      column: 'A',
      row: 13,
      value: {
        richText: [
          {
            font: { bold: true },
            text: `${this.translate.instant('activityHistory.excel.sheet.activityHistoriesData.title')}: `,
          },
          { text: this.translate.instant('activityHistory.excel.sheet.activityHistoriesData.description') },
        ],
      },
    });

    cells.push({
      column: 'A',
      row: 15,
      value: {
        richText: [
          {
            font: { bold: true },
            text: `${this.translate.instant('activityHistory.excel.sheet.userActivities.title')}: `,
          },
          { text: this.translate.instant('activityHistory.excel.sheet.userActivities.description') },
        ],
      },
    });

    cells.push({
      column: 'A',
      row: 17,
      value: {
        richText: [
          {
            font: { bold: true },
            text: `${this.translate.instant('activityHistory.excel.sheet.exampleActivityHistoriesData.title')}: `,
          },
          { text: this.translate.instant('activityHistory.excel.sheet.exampleActivityHistoriesData.description') },
        ],
      },
    });

    cells.push({
      column: 'A',
      row: 19,
      value: this.translate.instant('activityHistory.excel.sheet.overlapInformation.description'),
    });

    return {
      cells,
      defaultCellStyle,
      defaultColumnStyle,
    };
  }

  private static createReadmeWorksheet(worksheet: Worksheet, readmeConfig: IExcelReadmeSheet): void {
    let lastRowIndex: number = 0;

    readmeConfig.cells.forEach((cellConfig: IExcelCellConfiguration) => {
      const column: Column = worksheet.getColumn(cellConfig.column);
      column.width = readmeConfig.defaultColumnStyle?.width || column.width;
      column.style = _.defaultsDeep(cellConfig.columnStyle, readmeConfig.defaultColumnStyle);

      const cell: Cell = worksheet.getCell(`${cellConfig.column}${cellConfig.row}`);
      cell.value = cellConfig.value;
      cell.style = _.defaultsDeep(cellConfig.style, readmeConfig.defaultCellStyle);
      lastRowIndex = _.max([lastRowIndex, cell.row]);
    });

    const latestRow = worksheet.getRow(lastRowIndex + 1);

    if (latestRow !== undefined) {
      latestRow.values = null;
    }
  }

  private createTableWorksheet(
    workbook: Workbook,
    worksheet: Worksheet,
    worksheetConfiguration: ICreateExcelSheet,
    dateFormat: string = EXCEL_DEFAULT_DATE_FORMAT,
    timeFormat: string = EXCEL_DEFAULT_TIME_FORMAT,
    timezone: string = 'utc',
    columnSpecificDateFormats: Record<string, EExcelDateFormatOption>,
  ): void {
    const {
      params,
      withData = false,
      isDisabledColumnsFirstLine = false,
      addDateTimeFormula = false,
      excelRowFormatLimit = 1001,
      isProtected = true,
    } = worksheetConfiguration;

    const worksheetColumns: Partial<Column>[] = this.createWorksheetColumns(
      params.columns,
      workbook,
      dateFormat,
      timeFormat,
      timezone,
      addDateTimeFormula,
      columnSpecificDateFormats,
    );

    if (isDisabledColumnsFirstLine) {
      ExcelHelperService.changeOrderOfTheListColumns(params.columns, worksheetColumns);
    }
    worksheet.columns = worksheetColumns;
    this.setupHeaderRow(worksheet, params.columns);
    this.fillExcelSheetData(
      params,
      worksheet,
      excelRowFormatLimit,
      withData,
      dateFormat,
      timeFormat,
      columnSpecificDateFormats,
    );

    const latestRow = worksheet.getRow(excelRowFormatLimit + 1);
    if (latestRow !== undefined) {
      latestRow.values = null;
    }
  }

  private setupHeaderRow(worksheet: Worksheet, columns: IExcelColumnDefinition[]): void {
    const headerRow: Row = worksheet.getRow(1);
    headerRow.eachCell({ includeEmpty: true }, (cell) => {
      cell.protection = {
        locked: true,
      };

      const key: string = worksheet.getColumn(cell.col).key;

      if (_.find(columns, { key })?.isRequired) {
        cell.note = this.translate.instant('scwMatForm.validation.required');
      }
    });
  }

  private fillExcelSheetData(
    params: ICreateExcel,
    worksheet: Worksheet,
    excelRowFormatLimit: number,
    withData: boolean,
    dateFormat: string,
    timeFormat: string,
    columnSpecificDateFormats: Record<string, EExcelDateFormatOption>,
  ): void {
    ExcelHelperService.fillExcelColumnFormat(params.columns, dateFormat);

    for (
      let currentRowIndex: number = 2;
      currentRowIndex <= excelRowFormatLimit;
      currentRowIndex = currentRowIndex + 1
    ) {
      const row: Row = worksheet.getRow(currentRowIndex);
      const dataIndex: number = currentRowIndex - 2;

      ExcelHelperService.fillExcelRowData(
        params,
        worksheet,
        row,
        dataIndex,
        withData,
        dateFormat,
        timeFormat,
        columnSpecificDateFormats,
      );

      row.commit();
      if (withData && params.data.length && params.data[dataIndex]) {
        worksheet.insertRow(currentRowIndex, params.data[dataIndex]).commit();
      }
    }
  }

  private static fillExcelRowData(
    params: ICreateExcel,
    worksheet: Worksheet,
    row: Row,
    dataIndex: number,
    withData: boolean,
    dateFormat: string,
    timeFormat: string,
    columnSpecificDateFormats: Record<string, EExcelDateFormatOption>,
  ): void {
    params.columns.forEach((column: IExcelColumnDefinition, index: number) => {
      if (column.isAdditional) {
        return;
      }
      const cell: Cell = row.getCell(index + 1);
      cell.addName(column.key);

      const additionalColumn = ExcelHelperService.addListColumnValidation(column, cell);
      if (Object.keys(additionalColumn).length === 2) {
        ExcelHelperService.createAdditionalCell(column, additionalColumn, params.data, worksheet, dataIndex, withData);
      }

      ExcelHelperService.setupCellValidation(column, cell);

      if (withData && params.data.length && params.data[dataIndex]) {
        if (columnSpecificDateFormats[column.key] === EExcelDateFormatOption.Date) {
          dateFormat = customDotDateFormat;
        }

        ExcelHelperService.prepareDropdownColumnData(column, params.data[dataIndex], dateFormat, timeFormat);
      }
    });
  }

  private static createRequestedExcelFileType(
    workbook: Workbook,
    fileName: string,
    createAsCsvFile: boolean,
    indexOfWorksheetToPrintToCsv: number,
  ): Promise<void> {
    if (createAsCsvFile) {
      return ExcelHelperService.createCsvFile(workbook, fileName, indexOfWorksheetToPrintToCsv);
    }
    return ExcelHelperService.createExcelFile(workbook, fileName);
  }

  private static createCsvFile(
    workBook: Workbook,
    fileName: string,
    indexOfWorksheetToPrintToCsv: number,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      workBook.csv.writeBuffer({ sheetId: indexOfWorksheetToPrintToCsv }).then((data) => {
        try {
          const blob: Blob = new Blob([data], {
            type: 'text/csv',
          });
          fs.saveAs(blob, `${fileName}.csv`);
          resolve();
        } catch (e) {
          reject();
        }
      });
    });
  }

  private static createExcelFile(workBook: Workbook, fileName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      workBook.xlsx.writeBuffer().then((data) => {
        try {
          const blob: Blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          fs.saveAs(blob, `${fileName}.xlsx`);
          resolve();
        } catch (e) {
          reject();
        }
      });
    });
  }

  private setListTypeColumnConfigurationAndAddDisabledColumn(
    columns: Partial<IExcelColumnDefinition>[],
    column: Partial<IExcelColumnDefinition>,
    columnIndex: number,
  ): Partial<IExcelColumnDefinition> {
    const newColumn: Partial<IExcelColumnDefinition> = {
      header: `${column.header} ${this.translate.instant('apiErrorMessages.properties.id')}`,
      key: `${column.key}_ID`,
      width: column.dropdownOptions.primaryKeyColumnWidth || 10,
      type: ValueType.String,
      alignment: { vertical: 'middle', horizontal: 'center' },
      style: {
        font: {
          name: EXCEL_FONT_FAMILY.arial.name,
          color: { argb: 'C0C0C0' },
          family: EXCEL_FONT_FAMILY.arial.value,
          size: 10,
        },
        protection: {
          locked: true,
        },
      },
    };
    columns.splice(columnIndex + 1, 0, { ...newColumn, isAdditional: true });

    return ExcelHelperService.prepareDefaultColumn({ ...newColumn, number: columnIndex });
  }

  private createWorksheetColumns(
    columns: IExcelColumnDefinition[],
    workbook: Workbook,
    dateFormat: string,
    timeFormat: string,
    timezone: string,
    addDateTimeFormula: boolean,
    columnSpecificDateFormats: Record<string, EExcelDateFormatOption>,
  ): Partial<Column>[] {
    const worksheetColumns: Partial<Column>[] = [];

    for (let columnIndex: number = 0; columnIndex < columns.length; columnIndex = columnIndex + 1) {
      const column: IExcelColumnDefinition = columns[columnIndex];

      ExcelHelperService.prepareHiddenSheet(column, workbook);
      this.addDateColumnValidation(
        column,
        dateFormat,
        timeFormat,
        timezone,
        addDateTimeFormula,
        columnSpecificDateFormats,
      );

      worksheetColumns.push(ExcelHelperService.setColumnConfigurations(column, columnIndex));
      if (_.get(column, 'dataValidation.type', null) === ECellTypes.LIST) {
        worksheetColumns.push(this.setListTypeColumnConfigurationAndAddDisabledColumn(columns, column, columnIndex));
        columnIndex = columnIndex + 1;
      }
    }
    return worksheetColumns;
  }

  private addPromptAndErrorMessagesToDateColumn(
    column: IExcelColumnDefinition,
    dateFormat: string,
    timeFormat: string,
  ): void {
    if (column.type !== ValueType.Date) {
      return;
    }

    let dateColumnFormatText: string;

    if (dateFormat === customDotDateFormat) {
      dateColumnFormatText = this.translate.instant(`excel.date.format.customDotDateFormat`);
    } else {
      Object.keys(excelDateFormats).forEach((formatType: string) => {
        const format = excelDateFormats[formatType];

        if (format === dateFormat) {
          dateColumnFormatText = this.translate.instant(`excel.date.format.${formatType}`);
        }
      });

      if (column.specificDateFormat === EExcelDateFormatOption.Datetime) {
        Object.keys(excelTimeFormats).forEach((formatType: string) => {
          const format = excelTimeFormats[formatType];

          if (format === timeFormat) {
            const formatAsText = this.translate.instant(`excel.time.format.${formatType}`);
            dateColumnFormatText = dateColumnFormatText.concat(` ${formatAsText}`);
          }
        });
      }
    }

    const dateColumnFormat: string =
      column.specificDateFormat === EExcelDateFormatOption.Datetime ? `${dateFormat} ${timeFormat}` : dateFormat;
    const translateKey: string =
      column.specificDateFormat === EExcelDateFormatOption.Datetime ? 'excel.dateTime' : 'excel.date';
    column.dataValidation.error = this.translate.instant(`${translateKey}.error`, {
      header: column.header,
      dateColumnFormat: dateColumnFormatText,
      format: moment().format(dateColumnFormat),
    });

    column.dataValidation.prompt =
      column.dataValidation.prompt ??
      this.translate.instant(`${translateKey}.prompt`, {
        header: column.header,
        dateColumnFormat: dateColumnFormatText,
        format: moment().format(dateColumnFormat),
      });
  }

  private getExcelDateLocationFormula(
    dateFormat: string,
    searchDatePhase: string,
    isYear: boolean = false,
    isYearLocationLeft: boolean = false,
    splitCharacter: string = '/',
  ): string | undefined {
    const dateFormatPhaseIndex: number = this.getExcelDateFormulaLocationIndex(
      dateFormat,
      searchDatePhase,
      splitCharacter,
    );
    switch (dateFormatPhaseIndex) {
      case 0:
        return isYear ? 'LEFT' : `LEFT(${CELL_ADDRESS_CONSTANT},2)`;

      case 1:
        return `MID(${CELL_ADDRESS_CONSTANT},${isYearLocationLeft ? 6 : 4},2)`;

      case 2:
        return isYear ? 'RIGHT' : `RIGHT(${CELL_ADDRESS_CONSTANT},2)`;
      default:
        return undefined;
    }
  }

  private getExcelDateFormulaLocationIndex(
    dateFormat: string,
    searchDatePhase: string,
    splitCharacter: string,
  ): number {
    const dateFormatSplit: string[] = dateFormat.split(splitCharacter);
    return dateFormatSplit.indexOf(searchDatePhase);
  }
}
