import { AfterViewInit, Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { SemiManualCountButtonCardComponent } from './kpi-card-items/semi-manual-count-button-card/semi-manual-count-button-card.component';
import { CountsMetricCardComponent } from './kpi-card-items/counts-metric-card/counts-metric-card.component';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { filter, Subscription } from 'rxjs';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { IStationActivityHistoryState } from '../../../../store/station-activity-history/station-activity-history.model';
import * as KpiCardActions from '../../../../store/station-home/kpi-card/kpi-card.actions';
import { unitTypes } from '../../../../../constants';
import { TranslateService } from '@ngx-translate/core';
import { WorkOrderDurationMetricCardComponent } from './kpi-card-items/work-order-duration-metric-card/work-order-duration-metric-card.component';
import * as StationActivityHistoryActions from '../../../../store/station-activity-history/station-activity-history.actions';
import { ISemiManualCountButtonCardState } from '../../../../store/station-home/kpi-card/semi-manual-count-button-card/semi-manual-count-button-card.model';


@Component({
  selector: 'scw-station-home-kpi-card',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    SemiManualCountButtonCardComponent,
    CountsMetricCardComponent,
    WorkOrderDurationMetricCardComponent,
  ],
  templateUrl: './station-home-kpi-card.component.html',
  styleUrls: ['./station-home-kpi-card.component.scss'],
})
export class StationHomeKpiCardComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() public stationID: number;
  @Input() public isVirtualSensorsDefined: boolean = false;

  public productUnit: { formula: string; title: string; symbol: string; cycle: string } | null;
  public hasOngoingWorkOrder: boolean = false;
  public hasSelectedActivity: boolean = false;

  private subscriptions: Subscription[] = [];

  private readonly refreshDataIntervalMS: number = 60000; // 1 minute
  private hasSemiManualCountButtonComponentPendingCounts: boolean = false;

  private refreshDateIntervalID: NodeJS.Timer | null = null;
  private isVirtualCountsLoading$: boolean = false;

  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translateService: TranslateService,
    private readonly actions$: ActionsSubject,
    private readonly ngZone: NgZone,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('stationActivityHistoryStore').subscribe((state: IStationActivityHistoryState) => {
        this.hasOngoingWorkOrder = !!state.ongoingStationActivityHistory?.workOrder;
        this.hasSelectedActivity = !!state.ongoingStationActivityHistory?.activity;

        const unitIndex = state.ongoingStationActivityHistory?.workOrder?.product?.unit ?? null;

        if (unitIndex && unitTypes.hasOwnProperty(unitIndex)) {
          this.productUnit = this.translateService.instant(unitTypes[unitIndex]);
        }
      }),

      this.store.select('semiManualCountButtonCardStore').subscribe((state: ISemiManualCountButtonCardState): void => {
        this.isVirtualCountsLoading$ = state.isSendVirtualCounts;
      }),

      this.actions$
        .pipe(
          filter(
            (action: Action) =>
              action.type ===
                StationActivityHistoryActions.ActionTypes.UPDATE_ONGOING_STATION_ACTIVITY_HISTORY_LOADED ||
              action.type === StationActivityHistoryActions.ActionTypes.STATION_ACTIVITY_HISTORY_ACTIVITY_CHANGE_LOADED,
          ),
        )
        .subscribe((_) => {
          if (!this.hasSemiManualCountButtonComponentPendingCounts && !this.isVirtualCountsLoading$) {
            this.fetchStationQuantitiesData();
          }
        }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(
      changes.hasOwnProperty('stationID') &&
      changes['stationID'].currentValue !== changes['stationID'].previousValue &&
      !changes['stationID'].firstChange
    ) {
      this.killDataRefreshInterval();
      this.fetchStationQuantitiesData();
      this.scheduleDataRefreshInterval();
    }
  }

  public onSemiManualCountButtonComponentPendingCountsChange(hasPendingCounts: boolean): void {
    this.hasSemiManualCountButtonComponentPendingCounts = hasPendingCounts;
  }

  public ngAfterViewInit(): void {
    this.fetchStationQuantitiesData();

    this.scheduleDataRefreshInterval();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.killDataRefreshInterval();
  }

  private fetchStationQuantitiesData(): void {
    this.store.dispatch(KpiCardActions.fetchStationQuantities({ stationID: this.stationID }));
  }

  private scheduleDataRefreshInterval(ms?: number): void {
    this.killDataRefreshInterval();

    this.ngZone.runOutsideAngular(() => {
      this.refreshDateIntervalID = setInterval((): void => {
        if (this.hasSemiManualCountButtonComponentPendingCounts && !this.isVirtualCountsLoading$) {
          return;
        }

        this.fetchStationQuantitiesData();
      },
      ms ?? this.refreshDataIntervalMS,
    );
    });
  }

  private killDataRefreshInterval(): void {
    if (this.refreshDateIntervalID) {
      clearInterval(this.refreshDateIntervalID);
    }
  }
}
