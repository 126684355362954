import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ICustomDashboard, ICustomDashboardRequestPayload } from './custom-dashboards.model';

export enum CustomDashboardRoutes {
  BASE_CUSTOM_DASHBOARD = 'baseCustomDashboard',
  BULK_DELETE = 'bulkDelete',
  CLONE_DASHBOARD = 'cloneDashboard',
}

const basePath = 'custom-dashboards';

@Injectable({
  providedIn: 'root',
})
export class CustomDashboardService {
  private readonly routes: Record<CustomDashboardRoutes, string> = {
    [CustomDashboardRoutes.BASE_CUSTOM_DASHBOARD]: `${this.baseUrl}/${basePath}`,
    [CustomDashboardRoutes.BULK_DELETE]: `${this.baseUrl}/${basePath}/bulk/delete`,
    [CustomDashboardRoutes.CLONE_DASHBOARD]: `${this.baseUrl}/${basePath}/clone-dashboard`,
  };

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getCustomDashboardData(params: HttpParams): Observable<GetManyResponseInterface<ICustomDashboard>> {
    return this.http.get<GetManyResponseInterface<ICustomDashboard>>(
      this.routes[CustomDashboardRoutes.BASE_CUSTOM_DASHBOARD],
      { params },
    );
  }

  public updateCustomDashboard(
    id: number,
    requestPayload: Partial<ICustomDashboardRequestPayload>,
  ): Observable<BaseOneResponseInterface<ICustomDashboard>> {
    return this.http.patch<BaseOneResponseInterface<ICustomDashboard>>(
      `${this.routes[CustomDashboardRoutes.BASE_CUSTOM_DASHBOARD]}/${id}`,
      requestPayload,
    );
  }

  public createNewCustomDashboard(
    requestPayload: ICustomDashboardRequestPayload,
  ): Observable<BaseOneResponseInterface<ICustomDashboard>> {
    return this.http.post<BaseOneResponseInterface<ICustomDashboard>>(
      `${this.routes[CustomDashboardRoutes.BASE_CUSTOM_DASHBOARD]}`,
      requestPayload,
    );
  }

  public deleteCustomDashboard(id: number): Observable<GetManyResponseInterface<ICustomDashboard>> {
    return this.http.delete<GetManyResponseInterface<ICustomDashboard>>(
      `${this.routes[CustomDashboardRoutes.BASE_CUSTOM_DASHBOARD]}/${id}`,
    );
  }

  public bulkDeleteCustomDashboards(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.routes[CustomDashboardRoutes.BULK_DELETE]}`, {
      body: { customDashboards: ids },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public cloneCustomDashboard(
    id: number,
    requestPayload: Omit<ICustomDashboardRequestPayload, 'id'>,
  ): Observable<BaseOneResponseInterface<ICustomDashboard>> {
    return this.http.post<BaseOneResponseInterface<ICustomDashboard>>(
      `${this.routes[CustomDashboardRoutes.CLONE_DASHBOARD]}/${id}`,
      requestPayload,
    );
  }
}
