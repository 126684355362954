import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, mergeMap, startWith, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import * as ObjectActions from './station-activity-history.actions';
import * as oeeAppReducer from '../oee.reducer';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { Observable, of, Subject } from 'rxjs';
import { StationActivityHistoryService } from '../../shared/service/station-activity-history/station-activity-history.service';
import {
  IActivityAndLine,
  ISimplifiedActivityPhaseAndTask,
  IStationActivityHistory,
  IStationActivityHistoryCrudResponse,
  IStationDropdownResponse,
  NoActivityStation,
} from './station-activity-history.model';
import * as AppActions from '../app/actions';
import { HttpParams } from '@angular/common/http';
import { HelperService } from '../../shared/service/helper.service';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';
import { HTTPHelperService } from '../../shared/service/http/http.helper.service';
import { FieldTypes, IFilterOutput } from '../../shared/component/filter/advanced-filter/advanced-filter.model';
import { ActivityTypes } from '../../shared/model/enum/activity-types';
import * as _ from 'lodash';
import { AdvancedFilterService } from '../../shared/component/filter/advanced-filter/advanced-filter.service';
import { ESortType } from '../../../constants';
import { SemiManualCountButtonService } from '../../shared/service/station-home/station-home-kpi/semi-manual-count-button.service';
import { ISemiManualCountButtonCardState } from '../station-home/kpi-card/semi-manual-count-button-card/semi-manual-count-button-card.model';
import * as SemiManualCountButtonCardActions from '../station-home/kpi-card/semi-manual-count-button-card/semi-manual-count-button-card.actions';

@Injectable()
export class StationActivityHistoryEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: StationActivityHistoryService,
    private readonly helperService: HelperService,
    private readonly httpHelperService: HTTPHelperService,
    private readonly entityTranslatorService: EntityTranslatorService,
    private readonly advancedFilterService: AdvancedFilterService,
  ) {}

  public stationDropdownItemsLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.STATION_DROPDOWN_ITEMS_LOADING),
      switchMap((objectData: ObjectActions.StationDropdownItemsLoading) => {
        const params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
          join: ['ongoingCheckIns', 'ongoingStationActivityHistory', 'stationVirtualSensors', 'sensorList'],
          filters: [{ field: 'lineId', ids: [objectData.lineId] }],
          additionalCustomSearch: [{ isTrackerEnabled: { $eq: true } }],
          limit: 500,
        });
        return this.service.loadStationDropdownItems(params).pipe(
            switchMap((response: GetManyResponseInterface<IStationDropdownResponse>) => {
              response.data.forEach((data: IStationDropdownResponse) =>
              this.entityTranslatorService.translate(data.ongoingStationActivityHistory),
            );

            const actions: Action[] = [new ObjectActions.StationDropdownItemsLoaded(response)];

            if (objectData.hideLoader) {
              actions.push(new AppActions.HideLoader());
            }
            return of(...actions);
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public getOngoingStationActivityHistoryLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORY_LOADING),
      exhaustMap((objectData: ObjectActions.GetOngoingStationActivityHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
          join: ['station', 'task', 'task.equipment', 'workOrder', 'workOrder.productVersionInfo', 'workOrder.product'],
          additionalCustomSearch: [{ stationId: { $eq: objectData.stationId } }, { endedAt: { $isnull: true } }],
        });

        return this.service.getManyStationActivityHistories(params).pipe(
          switchMap((response: GetManyResponseInterface<IStationActivityHistoryCrudResponse>) => {
            response.data.forEach((stationActivityHistory: IStationActivityHistoryCrudResponse) =>
              this.entityTranslatorService.translate(stationActivityHistory),
            );
            return of(new ObjectActions.GetOngoingStationActivityHistoryLoaded(response));
          }),
          catchError(() => {
            return of(
              new ObjectActions.FetchError(ObjectActions.ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORY_LOADING),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public updateOngoingStationActivityHistoryLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.UPDATE_ONGOING_STATION_ACTIVITY_HISTORY_LOADING),
      mergeMap((objectData: ObjectActions.StationActivityHistoryActivityChangeLoading) => {
        SemiManualCountButtonService.sendCountsSubject.next();

        return new Observable().pipe(startWith(objectData));
      }),
      switchMap((objectData: ObjectActions.UpdateOngoingStationActivityHistoryLoading) => {
        return this.service.updateOngoingStationActivityHistory(objectData.stationId, objectData.requestData).pipe(
          switchMap((response: BaseOneResponseInterface<IStationActivityHistoryCrudResponse>) => {
            return of(
              new ObjectActions.UpdateOngoingStationActivityHistoryLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public stationActivityHistoryActivityChangeLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.STATION_ACTIVITY_HISTORY_ACTIVITY_CHANGE_LOADING),
      withLatestFrom(this.store.select('semiManualCountButtonCardStore')),
      mergeMap(
        ([objectData, state]: [
          ObjectActions.StationActivityHistoryActivityChangeLoading,
          ISemiManualCountButtonCardState,
        ]) => {
          if (state.isSendVirtualCounts && !state.isSendVirtualCountsCompleted) {
            const countResponseReceivedSubject: Subject<boolean> = new Subject<boolean>();

            return this.actions$.pipe(
              ofType(SemiManualCountButtonCardActions.sendVirtualCountsSuccess),
              takeUntil(countResponseReceivedSubject),
              switchMap(() => {
                return this.service
                  .stationActivityHistoryActivityChange(objectData.stationId, objectData.requestData)
                  .pipe(
                    tap(() => countResponseReceivedSubject.next(true)),
                    switchMap((response: BaseOneResponseInterface<ISimplifiedActivityPhaseAndTask>) => {
                      return of(new ObjectActions.StationActivityHistoryActivityChangeLoaded(response));
                    }),
                    catchError(() => {
                      return of(new ObjectActions.ChangeStationActivityError(), new AppActions.HideLoader());
                    }),
                  );
              }),
            );
          } else {
            return this.service.stationActivityHistoryActivityChange(objectData.stationId, objectData.requestData).pipe(
              switchMap((response: BaseOneResponseInterface<ISimplifiedActivityPhaseAndTask>) => {
                return of(new ObjectActions.StationActivityHistoryActivityChangeLoaded(response));
              }),
              catchError(() => {
                return of(new ObjectActions.ChangeStationActivityError(), new AppActions.HideLoader());
              }),
            );
          }
        },
      ),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public getActivityAndLine = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.GET_ACTIVITY_AND_LINE_LOADING),
      switchMap((objectData: ObjectActions.GetActivityAndLineLoading) => {
        return this.service
          .getActivityAndLine(
            objectData.ongoingStationActivityHistory.lineId,
            objectData.ongoingStationActivityHistory.activityId,
          )
          .pipe(
            switchMap((response: IActivityAndLine) => {
              this.entityTranslatorService.translate(response.activity);

              return of(
                new ObjectActions.GetActivityAndLineLoaded(response, objectData.ongoingStationActivityHistory),
                new AppActions.HideLoader(),
              );
            }),
            catchError(() => {
              return of(new AppActions.HideLoader());
            }),
          );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public getNoActivityStation = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.GET_NO_ACTIVITY_STATION_LOADING),
      switchMap((objectData: ObjectActions.GetNoActivityStationLoading) => {
        const params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
          fields: ['id', 'name', 'line.id', 'line.title', 'line.siteId'],
          join: ['line'],
          additionalCustomSearch: [{ isTrackerEnabled: { $eq: true } }],
        });

        return this.service.getOneStation(objectData.stationId, params).pipe(
          switchMap((response: BaseOneResponseInterface<NoActivityStation>) => {
            return of(
              new ObjectActions.GetNoActivityStationLoaded(response),
              new ObjectActions.UpdateMissingActivityMode(false),
              new AppActions.HideLoader(),
            );
          }),
          catchError(() => {
            return of(
              new ObjectActions.FetchError(ObjectActions.ActionTypes.GET_NO_ACTIVITY_STATION_LOADING),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public ongoingStationActivityHistoriesLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORIES_LOADING),
      switchMap((objectData: ObjectActions.GetOngoingStationActivityHistoriesLoading) => {
        const params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
          additionalCustomSearch: objectData.additionalCustomSearch,
        });

        return this.service.getManyStationActivityHistories(params).pipe(
          switchMap((response: GetManyResponseInterface<IStationActivityHistoryCrudResponse>) => {
            return of(new ObjectActions.GetOngoingStationActivityHistoriesLoaded(response));
          }),
          catchError(() => {
            return of(
              new ObjectActions.FetchError(ObjectActions.ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORY_LOADING),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public stationActivityHistoriesLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.GET_STATION_ACTIVITY_HISTORIES_LOADING),
      switchMap((objectData: ObjectActions.GetStationActivityHistoriesLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const requestParameters: { params: HttpParams } = this.getRequestParameters(objectData);
        return this.service.getManyStationActivityHistories(requestParameters.params).pipe(
          switchMap((response: GetManyResponseInterface<IStationActivityHistory>) => {
            return of(new ObjectActions.GetStationActivityHistoriesLoaded(response));
          }),
          catchError(() => {
            return of(
              new ObjectActions.FetchError(ObjectActions.ActionTypes.GET_STATION_ACTIVITY_HISTORIES_LOADING),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  private getRequestParameters(objectData: ObjectActions.GetStationActivityHistoriesLoading): { params: HttpParams } {
    let queryParams: HttpParams = new HttpParams();
    const missingDataConditions: any = [
      {
        $and: [
          { 'activity.activityType': { $ne: ActivityTypes.RUN_TIME } },
          { $or: [{ taskId: { $or: { $eq: '', $isnull: true } } }, { 'task.isMissingData': { $eq: 1 } }] },
        ],
      },
      { activityId: { $or: { $isnull: true, $eq: '' } } },
      { startedAt: { $isnull: true } },
      { endedAt: { $isnull: true } },
      {
        $and: [
          { 'activity.activityType': { $ne: ActivityTypes.IDLE_TIME } },
          { $or: [{ 'workOrder.woNumber': { $eq: '' } }, { workOrderId: { $isnull: true } }] },
        ],
      },
    ];

    const missingDataConditionsWithSearch: any = [
      {
        $or: [
          { 'workOrder.woNumber': { $cont: objectData.parameters.search } },
          { 'activity.name': { $cont: objectData.parameters.search } },
          { 'task.title': { $cont: objectData.parameters.search } },
          { 'product.description': { $cont: objectData.parameters.search } },
          { startedAt: { $cont: objectData.parameters.search } },
          { endedAt: { $cont: objectData.parameters.search } },
        ],
      },
      {
        $or: missingDataConditions,
      },
      {
        $and: objectData.parameters.isMissingData
          ? [{ 'task.meta': { $excl: '"withoutWorkOrder": 1' } }, { taskId: { $or: { $eq: '', $isnull: true } } }]
          : undefined,
      },
      { stationId: { $eq: objectData.parameters.stationId } },
      { endedAt: { $notnull: true } },
    ];

    const allActivitiesCondition: any = [
      {
        $or: [
          { 'workOrder.woNumber': { $cont: objectData.parameters.search } },
          { 'activity.name': { $cont: objectData.parameters.search } },
          {
            $and: [
              { 'activity.activityType': { $eq: ActivityTypes.RUN_TIME } },
              { 'product.description': { $cont: objectData.parameters.search } },
            ],
          },
          {
            $and: [
              { 'activity.activityType': { $ne: ActivityTypes.RUN_TIME } },
              { 'task.title': { $cont: objectData.parameters.search } },
            ],
          },
        ],
      },
      { stationId: { $eq: objectData.parameters.stationId } },
      { endedAt: { $notnull: true } },
    ];

    const searchDataQueryOptions: any = {
      $and: [
        { $or: objectData.parameters.isMissingData ? missingDataConditions : undefined },
        {
          $and: objectData.parameters.isMissingData
            ? [
                {
                  $or: [
                    { 'task.isMissingData': { $eq: 1 } },
                    {
                      $and: [
                        { 'task.meta': { $excl: '"withoutWorkOrder": 1' } },
                        { taskId: { $or: { $eq: '', $isnull: true } } },
                      ],
                    },
                  ],
                },
              ]
            : undefined,
        },
        { stationId: { $eq: objectData.parameters.stationId } },
        { endedAt: { $notnull: true } },
      ],
    };

    if (!_.isNil(objectData.parameters.search) && objectData.parameters.search !== '') {
      queryParams = queryParams.append('searchText', String(objectData.parameters.search));
    }

    queryParams = queryParams
      .set('page', String(objectData.parameters.page))
      .set('limit', String(objectData.parameters.pageSize))
      .append('join', 'activity')
      .append('join', 'workOrder')
      .append('join', 'workOrder.product')
      .append('join', 'task')
      .append('join', 'station')
      .set('sort', 'startedAt,DESC')
      .set('s', JSON.stringify(searchDataQueryOptions));

    if (objectData.parameters.orderBy !== undefined && objectData.parameters.orderBy !== null) {
      queryParams = queryParams.set(
        'sort',
        `${objectData.parameters.orderBy},${objectData.parameters.orderDesc === ESortType.DESCENDING ? 'DESC' : 'ASC'}`,
      );
    }

    const manualSearchQueryOptions: { [key: string]: any } = {
      $and: objectData.parameters.isMissingData ? missingDataConditionsWithSearch : allActivitiesCondition,
    };

    if (objectData.parameters.search) {
      queryParams = queryParams.set('s', JSON.stringify(manualSearchQueryOptions));
    }

    if (objectData.parameters.advancedFilter && objectData.parameters.advancedFilter.filters.length !== 0) {
      const advancedFilter: IFilterOutput[] = objectData.parameters.advancedFilter.filters;

      for (const filter of advancedFilter) {
        switch (filter.type) {
          case FieldTypes.predefined:
            manualSearchQueryOptions['$and'].push(
              this.advancedFilterService.generateQuery(
                filter.path,
                filter.type,
                filter.operator.name,
                filter.operator.type,
                objectData.parameters.advancedFilter.target,
                _.get(filter.value, `[0][${filter.searchBy}]`, ''),
              ),
            );

            break;
          default:
            if (filter.path === 'task.title') {
              manualSearchQueryOptions['$and'].push({
                $or: [
                  {
                    $and: [
                      { 'activity.activityType': { $eq: ActivityTypes.RUN_TIME } },
                      this.advancedFilterService.generateQuery(
                        'product.description',
                        filter.type,
                        filter.operator.name,
                        filter.operator.type,
                        objectData.parameters.advancedFilter.target,
                        filter.value,
                      ),
                    ],
                  },
                  {
                    $and: [
                      { 'activity.activityType': { $ne: ActivityTypes.RUN_TIME } },
                      this.advancedFilterService.generateQuery(
                        filter.path,
                        filter.type,
                        filter.operator.name,
                        filter.operator.type,
                        objectData.parameters.advancedFilter.target,
                        filter.value,
                      ),
                    ],
                  },
                ],
              });
            } else {
              manualSearchQueryOptions['$and'].push(
                this.advancedFilterService.generateQuery(
                  filter.path,
                  filter.type,
                  filter.operator.name,
                  filter.operator.type,
                  objectData.parameters.advancedFilter.target,
                  filter.value,
                ),
              );
            }

            break;
        }

        if (objectData.parameters.isMissingData) {
          manualSearchQueryOptions['$and'].push({
            $and: [
              {
                $or: [
                  { 'task.isMissingData': { $eq: 1 } },
                  {
                    $and: [
                      { 'task.meta': { $excl: '"withoutWorkOrder": 1' } },
                      { taskId: { $or: { $eq: '', $isnull: true } } },
                    ],
                  },
                ],
              },
            ],
          });
        }
      }

      queryParams = queryParams.set('s', JSON.stringify(manualSearchQueryOptions));
    }
    return {
      params: queryParams,
    };
  }
}
